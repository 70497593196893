// src/App.js
import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from './Navbar';
import HeaderSection from './HeaderSection';
import OptionsSection from './OptionsSection';
import TestimonialsSection from './TestimonialsSection';
import PropertiesSectionSold from './PropertiesSectionSold';
import ContactFormSection from './ContactFormSection';
import FooterSection from './FooterSection';
import SalesGraph from './SalesGraph';
import { CssBaseline, Box } from '@mui/material';
import MessengerBubble from './MessengerBubble';

function App() {
  return (
    <div>
      <CssBaseline />

      <Helmet>
        <title>Ganesh Neupane - Trusted Real Estate Agent</title>
        <meta name="description" content="Ganesh Neupane, a highly rated Indian-Nepalese real estate agent specializing in vaastu houses, house pujas, and community involvement." />
        <meta name="keywords" content="real estate agent, Indian Nepalese, highly rated, vaastu house, house puja, community involvement" />
        
        {/* Structured Data using JSON-LD for Google Search */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "RealEstateAgent",
            "name": "Ganesh Neupane",
            "areaServed": "Your City",
            "description": "Highly rated Indian Nepalese real estate agent specializing in vaastu house, house puja, and community-focused services.",
            "url": "https://ganeshneupane.com",
            "contactPoint": {
              "@type": "ContactPoint",
              "contactType": "Customer Service"
            }
          })}
        </script>
      </Helmet>
      {/* Messenger Bubble */}
      <MessengerBubble/>

      {/* Navbar */}
      <Navbar />

      {/* Home Section with soft bluish gradient background */}
      <Box
        id="home"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          padding: '20px',
          background: 'linear-gradient(135deg, #f0f4f8, #d9e6f1)', // Very light blue-gray gradient
        }}
      >
        <Box sx={{ width: '100%', maxWidth: '1200px' }}>
          <HeaderSection />
        </Box>
      </Box>

      {/* Options Section with soft bluish gradient background */}
      <Box
        id="options"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          padding: '20px',
          background: 'linear-gradient(135deg, #e7eff7, #d0dfe9)', // Slightly deeper blue-gray
        }}
      >
        <Box sx={{ width: '100%', maxWidth: '1200px' }}>
          <OptionsSection />
        </Box>
      </Box>

      {/* Sales Graph Section */}
      <Box
        id="salesgraph"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          padding: '20px',
          background: 'linear-gradient(135deg, #f1f6fa, #dbe6f0)', // Lightest blue-gray gradient
        }}
      >
        <Box sx={{ width: '100%', maxWidth: '1200px' }}>
          <SalesGraph />
        </Box>
      </Box>

      {/* Testimonials Section with soft bluish gradient background */}
      <Box
        id="testimonials"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          padding: '20px',
          background: 'linear-gradient(135deg, #edf2f7, #cfdbe6)', // Balanced blue-gray gradient
        }}
      >
        <Box sx={{ width: '100%', maxWidth: '1200px' }}>
          <TestimonialsSection />
        </Box>
      </Box>

      {/* Properties Section with soft bluish gradient background */}
      <Box
        id="properties"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          padding: '20px',
          background: 'linear-gradient(135deg, #e3ecf4, #cad5de)', // Slightly darker blue-gray
        }}
      >
        <Box sx={{ width: '100%', maxWidth: '1200px' }}>
          <PropertiesSectionSold />
        </Box>
      </Box>

      {/* Contact Section with soft bluish gradient background */}
      <Box
        id="contact"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          padding: '20px',
          background: 'linear-gradient(135deg, #f1f6fa, #dbe6f0)', // Lightest blue-gray gradient
        }}
      >
        <Box sx={{ width: '100%', maxWidth: '1200px' }}>
          <ContactFormSection />
        </Box>
      </Box>

      {/* Footer */}
      <FooterSection />

      {/* Messenger Bubble */}
      <MessengerBubble/>
    </div>
  );
}

export default App;
