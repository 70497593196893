// src/components/HeaderSection.js
import React from 'react';
import { Helmet } from 'react-helmet';
import { Grid, Avatar, Box, Typography, IconButton } from '@mui/material';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import ZillowIcon from './ZillowIcon'; // Custom component for Zillow logo
import Navbar from './Navbar';
import me from './me.JPG';
import house1 from './house1.jpg';
import house2 from './house2.jpg';
import house3 from './house3.jpg';
import house4 from './house4.jpg';

const HeaderSection = () => (
  <header>

    <Helmet>
      <title>Ganesh Neupane - Trusted Real Estate Agent</title>
      <meta name="description" content="Ganesh Neupane, a highly rated Indian-Nepalese real estate agent specializing in vaastu houses, house pujas, and community involvement." />
      <meta name="keywords" content="real estate agent, Indian Nepalese, highly rated, vaastu house, house puja, community involvement" />
      
      {/* Structured Data using JSON-LD for Google Search */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "RealEstateAgent",
          "name": "Ganesh Neupane",
          "areaServed": "Your City",
          "description": "Highly rated Indian Nepalese real estate agent specializing in vaastu house, house puja, and community-focused services.",
          "url": "https://ganeshneupane.com",
          "contactPoint": {
            "@type": "ContactPoint",
            "contactType": "Customer Service"
          }
        })}
      </script>
    </Helmet>

    {/* Header Section with Layered Slideshow Background */}
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        color: '#fff',
        padding: '60px 20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '80vh',
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'linear-gradient(to right, rgba(0,0,0,0.5), rgba(0,0,0,0.3))',
          zIndex: 1,
        },
      }}
    >
      {/* Slideshow Images with Blur Effect */}
      {[house1, house2, house3, house4].map((image, index) => (
        <Box
          key={index}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            opacity: 0,
            filter: 'blur(4px)', // Apply subtle blur effect
            animation: `fadeInOut 24s infinite ${index * 6}s`,
            zIndex: 0,
          }}
        />
      ))}

      {/* Keyframes for Smooth Fade Effect */}
      <style>
        {`
          @keyframes fadeInOut {
            0%, 10% { opacity: 0; }
            15%, 40% { opacity: 1; }
            45%, 100% { opacity: 0; }
          }
        `}
      </style>

      <Grid container spacing={4} alignItems="center" justifyContent="center" sx={{ position: 'relative', zIndex: 2 }}>
        {/* Left Side: Photo and Social Links */}
        <Grid item xs={12} md={4} data-aos="fade-right" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Avatar
          alt="Ganesh Neupane"
          src={me}
          sx={{
            width: 250,          // Width for rectangular frame
            height: 300,         // Height for rectangular frame
            borderRadius: '8px', // Rounded corners for a rectangular shape
            border: '4px solid white',
            marginBottom: 2,
            marginTop: 8         // Adjust this value to move the avatar down
          }}
        />
          {/* Social Media Links */}
          <Box sx={{ display: 'flex', gap: 3 }}>
            <IconButton
              component="a"
              href="https://www.facebook.com/GaneshalpineRealtor/" // Replace with actual Facebook URL
              target="_blank"
              rel="noopener"
              aria-label="Facebook"
              sx={{ color: '#4267B2' }}
            >
              <FaFacebookF size={30} />
            </IconButton>
            <IconButton
              component="a"
              href="https://www.instagram.com/realtorganeshalpine?igsh=ZTN4MmJiOGVlcDd6" // Replace with actual Instagram URL
              target="_blank"
              rel="noopener"
              aria-label="Instagram"
              sx={{ color: '#E4405F' }}
            >
              <FaInstagram size={30} />
            </IconButton>
            <IconButton
              component="a"
              href="https://www.zillow.com/profile/realtorganesh" // Replace with actual Zillow URL
              target="_blank"
              rel="noopener"
              aria-label="Zillow"
              sx={{ color: '#0074E4' }}
            >
              <ZillowIcon width={30} height={30} />
            </IconButton>
          </Box>
        </Grid>

        {/* Right Side: Text Content */}
        <Grid item xs={12} md={8} data-aos="fade-left">
          {/* Quote and Author Section */}
          <Box sx={{ maxWidth: '600px', marginBottom: 3 }}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 'bold',
                fontFamily: 'Roboto Slab, serif',
                fontStyle: 'italic',
                color: '#f5f5f5', // Light off-white color for contrast
                textShadow: '1px 1px 3px rgba(0, 0, 0, 0.7)', // Subtle shadow for depth
                textAlign: 'left', // Aligns all text to the same left margin
              }}
            >
              "A home is a place to start your story."
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                fontFamily: 'Roboto Slab, serif',
                fontStyle: 'italic',
                color: '#e0e0e0',
                textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
                textAlign: 'right', // Aligns the author to the right
                display: 'block', // Forces the author to be on a new line
                marginTop: '4px',
              }}
            >
              - Anita Renfroe
            </Typography>
          </Box>

          {/* Introduction */}
          <Typography
            variant="subtitle1"
            sx={{
              mb: 3,
              color: '#e0e0e0', // Light gray for enhanced visibility
              fontSize: '1.1rem',
              textAlign: 'left', // Keeps consistent left alignment
              maxWidth: '600px',
            }}
          >
            Hello, and welcome to my world of real estate! I'm Ganesh Neupane, your dedicated and passionate Realtor®, committed to turning your property dreams into reality.
          </Typography>

          {/* Mission Statement */}
          <Typography
            variant="body1"
            sx={{
              lineHeight: 1.8,
              color: '#d9d9d9', // Light gray for readability
              fontSize: '1rem',
              textAlign: 'left', // Consistent alignment
              maxWidth: '600px',
            }}
          >
            My mission is simple: to provide exceptional service with a personal touch. I understand that buying or selling a property can be both exciting and stressful, which is why I take pride in being a reliable and trusted resource for all my clients. Listening to your needs, understanding your goals, and tailoring my approach to suit your unique circumstances are the keys to our successful partnership.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  </header>
);

export default HeaderSection;