// src/components/KeyMetricsSection.js
import React from 'react';
import { Card, Typography, Box, Grid } from '@mui/material';
import { FaUsers, FaHome, FaDollarSign } from 'react-icons/fa';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

const KeyMetricsSection = () => {
  const metrics = [
    {
      icon: <FaUsers size={60} color="#1d43b5" />,
      label: 'Represented Customers',
      end: 50,
      suffix: '+',
      color: '#1d43b5',
    },
    {
      icon: <FaHome size={60} color="#ff7300" />,
      label: 'Properties Rented',
      end: 5,
      suffix: '+',
      color: '#ff7300',
    },
    {
      icon: <FaDollarSign size={60} color="#82ca9d" />,
      label: 'Average House Price',
      end: 390000,
      prefix: '$',
      separator: ',',
      color: '#82ca9d',
    },
  ];

  return (
    <Box
      sx={{
        padding: '40px 20px',
        backgroundColor: '#f5f5f5',
      }}
    >
      <Typography
        variant="h4"
        component="h2"
        gutterBottom
        sx={{
          color: '#333333',
          textAlign: 'center',
          marginBottom: '40px',
        }}
      >
        
      </Typography>

      <Grid container spacing={4} justifyContent="center">
        {metrics.map((metric, index) => (
          <MetricCard
            key={metric.label}
            {...metric}
            delay={index * 0.5} // Optional delay for staggered animations
          />
        ))}
      </Grid>
    </Box>
  );
};

const MetricCard = ({
  icon,
  label,
  end,
  prefix,
  suffix,
  separator,
  color,
  delay = 0,
}) => {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.3,
  });

  return (
    <Grid item xs={12} md={4}>
      <Card
        ref={ref}
        sx={{
          padding: '20px',
          textAlign: 'center',
          transition: 'transform 0.3s',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
        aria-label={label}
      >
        {icon}
        <Typography variant="h6" component="h4" sx={{ marginTop: '20px' }}>
          {label}
        </Typography>
        <Typography
          variant="h3"
          component="p"
          sx={{ color: color, marginTop: '10px' }}
        >
          <CountUp
            start={inView ? null : 0}
            end={inView ? end : 0}
            duration={2}
            prefix={prefix}
            suffix={suffix}
            separator={separator}
            useEasing
          >
            {({ countUpRef, start }) => {
              if (inView) {
                setTimeout(() => {
                  start();
                }, delay * 1000);
              }
              return <span ref={countUpRef} />;
            }}
          </CountUp>
        </Typography>
      </Card>
    </Grid>
  );
};

export default KeyMetricsSection;
