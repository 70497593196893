// src/components/MessengerBubble.js
import React from 'react';
import { Fab, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';

// StyledFab with increased size and transparency
const StyledFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: 24,
  right: 24,
  zIndex: 1000,
  backgroundColor: 'rgba(0, 132, 255, 0.85)', // Messenger blue with 85% opacity
  '&:hover': {
    backgroundColor: 'rgba(0, 107, 191, 0.85)', // Darker blue on hover with same opacity
  },
  width: 70, // Increased width
  height: 70, // Increased height
}));

const MessengerBubble = () => {
  const handleClick = () => {
    window.open('https://m.me/GaneshalpineRealtor', '_blank', 'noopener,noreferrer');
  };

  return (
    <Tooltip title="Chat with us on Messenger!" arrow>
      <StyledFab onClick={handleClick} aria-label="Messenger Chat">
        <FontAwesomeIcon icon={faFacebookMessenger} size="2x" color="white" />
      </StyledFab>
    </Tooltip>
  );
};

export default MessengerBubble;
